<template>
  <div class="recommend">
    <div v-title data-title="邀请返利"></div>

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-friendRecommend.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动平台:</p>
              <span style="color:var(--li-colortext);">所有游戏厅</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>活动条件:</p>
              <span style="color:var(--li-colortext);">邀请者需完成实名并达到1000流水即可参加邀请活动</span>
            </div>
            <div class="title-item">
              <p>奖励规则:</p>
              <span style="color:var(--li-colortext);">受邀者需要完成实名验证并有一次存款视为完成活动，受邀彩金系统直接发放</span>
            </div>
            <div style="color:red;font-size:14px;line-height: 24px;">
              注:邀请者不但有一次开客彩金奖励，还可获得受邀者在平台内的存款返利和流水返利！
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                        color: #fff;
                        border-top-left-radius: 10px;
                        border-right: 1px solid #e2d2bf;
                      ">
                  VIP级别
                </td>
                <td style="color: #fff; border-right: 1px solid #e2d2bf">
                  开客彩金
                </td>
                <td style="color: #fff; border-right: 1px solid #e2d2bf">
                  存款返利
                </td>
                <td style="color: #fff; border-right: 1px solid #e2d2bf">
                  流水返利
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">
                  受邀彩金
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.kkbouns }}</td>
                <td>{{ item.dptrebate }}</td>
                <td>{{ item.beatrebate }}</td>
                <td>{{ item.hbbouns }}</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box" @click="gotoFriend">点击进入</div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                满足参与活动条件的会员在本页面“点击进入”或个人中心“邀请好友”入口进入详情页。点击复制邀请链接或保存二维码发给受邀者前往注册，受邀者在注册页面完成注册即可绑定关系。
              </li>
              <li>
                <span>2.</span>
                邀者在打开链接或二维码进入注册页面时，切勿修改链接刷新页面或切换页面重新打开。点开页面后直接注册即可，如有问题请及时联系客服。
              </li>
              <li>
                <span>3.</span>
                邀请者还可获得受邀者的邀请收益50%，例如A邀请B，B邀请C，A还可获得B邀请收益的50%！
              </li>
              <li>
                <span>4.</span>
                邀请收益的奖励每日结算，1倍流水即可出款。
              </li>

              <li>
                <span>5.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>6.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>7.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      cueTheme: '',
    }
  },
  mounted () {
    this.cueTheme = window.localStorage.getItem('cueTheme')

    this.getdata()
  },
  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata () {
      this.showLoading = true
      var data = '?type=invite'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    gotoFriend () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'gotorouter',
                routetname: '/pages/my/inviteFriend',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              appUrl: '/pages/my/inviteFriend',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f8fafa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.recommend {
  .btn-box {
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: url('https://sabbont.gz.bcebos.com/h5/webviewImg/pcbb/apply3.png') no-repeat;
    background-size: 100% 100%;
    margin: 25px 0px 15px 0px;
  }
}

.rule-condition {
  padding: 0 16px;

  .rule-condition-title {
    height: 34px;
    background: url('https://sabbont.gz.bcebos.com/h5/webviewImg/pcbb/conditiontitl.png');
    background-size: 100% 100%;
    position: relative;
  }

  .rule-acontents {
    padding-left: 10px;
    margin-top: 15px;

    .title-item {
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;

      >span {
        color: #303030;
      }
    }
  }
}

.rule-content {
  padding: 0 16px;

  .rule-content-title {
    margin-top: 20px;
    height: 34px;
    background: url('https://sabbont.gz.bcebos.com/h5/webviewImg/pcbb/contenttitle.png');
    background-size: 100% 100%;
    position: relative;
  }
}

.rule-detail {
  padding: 0 16px;

  .rule-detail-title {
    margin-top: 20px;
    height: 34px;
    background: url('https://sabbont.gz.bcebos.com/h5/webviewImg/pcbb/detailtitle.png');
    background-size: 100% 100%;
    position: relative;
  }
}
</style>
